<template>

	<div class="section_wrap pt-50 pb-80">
		<div class="container">
			<div class="row">
				<!-- 카드 정보 -->
				<div class="cont_box cont_box_type2 mt-20">

					<table class="nft_info_table">
						<colgroup>
							<col style="width: 35%">
							<col style="width: 40%">
							<col style="width: 25%">
						</colgroup>
						<thead>
						<tr>
							<th class="t-left">입출금 일시</th>
							<th class="t-left">거래ID</th>
							<th class="t-right">상태</th>
						</tr>
						</thead>
						<tbody>
						<template
							v-if="items_history.length > 0"
						>
							<template
								v-for="(history, index) in items_history"
							>
								<tr
									:key="'history_' + index"
								>
									<td>{{  history.tranmsndt }}</td>
									<td>
										<a
											:href="explorer + history.transaction_id" target="txid"
										>{{  history.transaction_id | maxLength(15, '...')}}</a>
									</td>
									<td class="tb_ing_gray">{{  history.tranmsn_state_name }}</td>
								</tr>
								<tr
									:key="'history2_' + index"
								>
									<td>{{  history.tranmsn_div_name }}</td>
									<td class="tb_ing_gray">{{  history.withdrawal_member_nickname }} {{  history.receive_member_nickname }} </td>
								</tr>
							</template>
						</template>
						<tr
							v-else
						>
							<td colspan="3" style="text-align: center; padding: 50px">no data</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!-- //카드 정보 -->
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia32'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: '입출금 이력'
				,type: 'popup'
				,title: '입출금 이력'
			}
			, currency_id: this.$route.params.id
			, wallet: {

			}
			,items_history: [

			]
			, item_wallet: {}
		}
	}
	,computed: {
		is_sample: function(){
			if(process.env.VUE_APP_TYPE == 'sample'){
				return true
			}else{
				return false
			}
		}
		, explorer: function(){
			let t = 'https://baobab.scope.klaytn.com/tx/0x'
			let location = document.location.href
			if(location.indexOf('https://mafinft.com/') > -1){
				t = 'https://scope.klaytn.com/tx/0x'
			}

			return t
		}
	}
	,methods: {
		getWalletHistory: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					,url: this.$api_url.api_path.get_wallet_history
					,data: {
						member_number: this.user.member_number
						, coin_token_code: this.currency_id
						, page_number: this.$language.base.page_number
						, pagerecnum: this.$language.base.pagerecnum
					}
					,type: true
				})

				if(result.success){
					this.items_history = result.data.deptwidal_list
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				this.$log.console(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	,async created() {
		this.$emit('onLoad', this.program)
		await this.getWalletHistory()
	}

}
</script>